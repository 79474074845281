import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Error = () => {

  const browser = typeof window !== "undefined" && window;
  return (
    <>
      {browser && (
        <>
          <Seo title="404" />
          <Layout>
            <main className="error-page">
              <div className="error-container">
                <h1>Nada por aquí</h1>
                <Link to="/" className="btn">
                  Vuelve al inicio
                </Link>
              </div>
            </main>
          </Layout>
        </>
       )}
    </>
  );
}


export default Error
